'use client';

import React, {
  type ChangeEvent,
  useCallback,
  useState,
  type MouseEvent,
  useEffect,
  useRef,
} from 'react';
import { Separator } from './separator';
import { SocialSigninButtons } from './socialSigninButtons';
import { useRouter, redirect } from 'next/navigation';
import { signIn } from 'next-auth/react';

const EMAIL_ROUTE = 'email';
const QUERY_PARAM = 'e';

type AuthProvider = 'google' | 'github' | 'email' | 'credentials' | undefined;

export const SignInForm = () => {
  const router = useRouter();
  const formRef = useRef<HTMLFormElement>(null);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [userEmail, setUserEmail] = useState<string>('');
  const [provider, setProvider] = useState<AuthProvider>(undefined);
  const [emailSelected, setEmailSelected] = useState<boolean>(false);
  const emailOnChange = useCallback((event: ChangeEvent<HTMLInputElement>) => {
    setUserEmail(event.target.value);
  }, []);
  const selectEmail = useCallback(
    (event: MouseEvent<HTMLButtonElement>) => {
      event.preventDefault();
      event.stopPropagation();
      if (!formRef.current?.reportValidity()) return;
      const url = new URL(document.URL);
      router.push(
        `${url.origin}${url.pathname}/${EMAIL_ROUTE}?${QUERY_PARAM}=${encodeURIComponent(
          userEmail,
        )}`,
      );
    },
    [router, userEmail],
  );
  const googleCallback = useCallback(() => {
    (async function () {
      setIsLoading(true);
      setProvider('google');
      await signIn('google');
    })();
  }, []);
  const githubCallback = useCallback(() => {
    (async function () {
      setIsLoading(true);
      setProvider('github');
      await signIn('github');
    })();
  }, []);
  useEffect(() => {
    const url = new URL(document.URL);
    if (!['/auth/signin', `/auth/signin/${EMAIL_ROUTE}`].includes(url.pathname)) {
      redirect(`${url.origin}/auth/signin`);
    }
    const queryEmail = url.searchParams.get(QUERY_PARAM);
    if (queryEmail !== null && queryEmail !== '') {
      setEmailSelected(true);
      setUserEmail(queryEmail);
    }
  }, []);
  return (
    <>
      <form ref={formRef} className="space-y-4 md:space-y-6" action="#">
        <div>
          <label
            htmlFor="email"
            className="mb-2 block text-sm font-medium text-gray-900 dark:text-white">
            Your email
          </label>
          <input
            type="email"
            name="email"
            id="email"
            className="focus:ring-primary-600 focus:border-primary-600 block w-full rounded-lg border border-gray-300 bg-gray-50 p-2.5 text-gray-900 sm:text-sm dark:border-gray-600 dark:bg-gray-700 dark:text-white dark:placeholder-gray-400 dark:focus:border-blue-500 dark:focus:ring-blue-500"
            placeholder="name@company.com"
            required
            value={userEmail}
            onChange={emailOnChange}
            disabled={isLoading}
          />
        </div>
        {emailSelected && (
          <div>
            <label
              htmlFor="password"
              className="mb-2 block text-sm font-medium text-gray-900 dark:text-white">
              Password
            </label>
            <input
              type="password"
              name="password"
              id="password"
              placeholder=""
              className="focus:ring-primary-600 focus:border-primary-600 block w-full rounded-lg border border-gray-300 bg-gray-50 p-2.5 text-gray-900 sm:text-sm dark:border-gray-600 dark:bg-gray-700 dark:text-white dark:placeholder-gray-400 dark:focus:border-blue-500 dark:focus:ring-blue-500"
              required
              disabled={isLoading}
            />
          </div>
        )}
        {emailSelected && (
          <div className="flex items-center justify-between">
            <a
              href="#"
              className="text-primary-600 dark:text-primary-500 text-sm font-medium hover:underline">
              Forgot password?
            </a>
          </div>
        )}
        <button
          type={emailSelected ? 'submit' : 'button'}
          onClick={emailSelected ? undefined : selectEmail}
          disabled={isLoading}
          className="light:text-black disabled:bg-primary-600/50 bg-primary-600 hover:bg-primary-700 focus:ring-primary-300 dark:hover:bg-primary-700 dark:focus:ring-primary-800 w-full rounded-lg px-5 py-2.5 text-center text-sm font-bold text-white focus:outline-none focus:ring-4">
          {emailSelected ? 'Sign in' : 'Continue'}
        </button>
      </form>
      <Separator label={emailSelected ? 'or' : 'or sign in with'} />
      {!emailSelected && (
        <SocialSigninButtons
          isLoading={isLoading}
          googleCallback={googleCallback}
          githubCallback={githubCallback}
          providerInUse={provider}
        />
      )}
      {emailSelected && (
        <button
          type="button"
          className="light:text-black bg-primary-500 hover:bg-primary-600 focus:ring-primary-300 dark:hover:bg-primary-700 dark:focus:ring-primary-800 w-full rounded-lg px-5 py-2.5 text-center text-sm font-medium text-white focus:outline-none focus:ring-4">
          Send a sign-in link
        </button>
      )}
    </>
  );
};
