import React from 'react';

export type SeparatorProps = {
  label?: string;
};

export const Separator = ({ label = '' }: SeparatorProps) => {
  return (
    <div className="inline-flex w-full items-center justify-center">
      <hr className="my-8 h-px w-full border-0 bg-gray-200 dark:bg-gray-700" />
      <span className="absolute left-1/2 -translate-x-1/2 bg-white px-3 font-medium text-gray-900 dark:bg-gray-800 dark:text-white">
        {label}
      </span>
    </div>
  );
};
